import React, { useState } from 'react';
import {
  OverlayTrigger,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from 'react-bootstrap';
import styled from 'styled-components';
import Sun from '../assets/images/Sun.svg';
import Snowflake from '../assets/images/Snowflake.svg';
import Wind from '../assets/images/Wind.svg';
import WithoutWind from '../assets/images/WithoutWind.svg';

import SunAct from '../assets/images/SunAct.svg';
import SnowflakeAct from '../assets/images/SnowflakeAct.svg';
import WindAct from '../assets/images/WindAct.svg';
import WithoutWindAct from '../assets/images/WithoutWindAct.svg';

interface SideAreaComponentProps {
  radios: any;
  selectedToggle: string;
  nameToggle: string;
  disabled?: string;
  icons?: [];
  onProductTypeChange: (selectedCat: string, nameCat: string) => void;
}

const StyledOverlayTrigger = styled(OverlayTrigger)`
  .custom-tooltip > .tooltip-inner {
    background-color: #f8d7da; /* Light red background */
    color: #721c24; /* Dark red text */
    border: 1px solid #f5c6cb; /* Light red border */
    border-radius: 4px; /* Rounded corners */
    padding: 5px; /* Padding */
  }
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)``;

const MultipleDuelSwitch: React.FC<SideAreaComponentProps> = ({
  radios,
  selectedToggle,
  nameToggle,
  disabled,
  onProductTypeChange,
}) => {
  const [isToggled, setIsToggled] = useState('0');

  React.useEffect(() => {
    setIsToggled(selectedToggle);
  }, [selectedToggle]);

  const handleSwitchType = (value: string) => {
    setIsToggled(value);
    onProductTypeChange(value, nameToggle);
  };

  return (
    <StyledToggleButtonGroup
      type="radio"
      name={`options-${nameToggle}`}
      id={`options-${nameToggle}`}
      size={'lg'}
      value={isToggled}
      className="mb-2 custom-toggle"
      style={{ position: 'relative' }}
    >
      <StyledOverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip-top1" className="custom-tooltip">
            {`Journée ensoleillée d'été sans vent`}
          </Tooltip>
        }
      >
        <ToggleButton
          type="radio"
          variant="secondary"
          name="radio"
          value={radios[0].value}
          checked={isToggled === radios[0].value}
          onChange={(e) => handleSwitchType(e.target.value)}
          id={`${nameToggle}-${0}`}
          disabled={disabled === radios[0].value || disabled === 'O'}
          style={{ width: '6rem' }}
          className="custom"
        >
          <img src={isToggled === radios[0].value ? SunAct : Sun} alt="" />
          <img
            src={isToggled === radios[0].value ? WithoutWindAct : WithoutWind}
            alt=""
          />
        </ToggleButton>
      </StyledOverlayTrigger>

      <StyledOverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip-top2" className="custom-tooltip">
            {`Nuit d'hiver venteuse`}
          </Tooltip>
        }
      >
        <ToggleButton
          key={1}
          type="radio"
          variant="secondary"
          name="radio"
          value={radios[1].value}
          checked={isToggled === radios[1].value}
          onChange={(e) => handleSwitchType(e.target.value)}
          id={`${nameToggle}-${1}`}
          disabled={disabled === radios[1].value || disabled === 'O'}
          style={{ width: '6rem' }}
          className="custom"
        >
          <img
            src={isToggled === radios[1].value ? SnowflakeAct : Snowflake}
            alt=""
          />
          <img src={isToggled === radios[1].value ? WindAct : Wind} alt="" />
        </ToggleButton>
      </StyledOverlayTrigger>
    </StyledToggleButtonGroup>
  );
};

export default MultipleDuelSwitch;
