const RightAreaComponent = () => {

  return (
    <div className='right-area'>
      
    </div>
  );
};

export default RightAreaComponent;
