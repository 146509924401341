import { SegmentProperties } from '../../../models/SegmentFeature';
import { PathLayer } from '@deck.gl/layers/typed';
import { useAppSelector } from '../../../store/hooks';
import { selectGouletSegmentsByZone } from '../../../store/mainReducer';
import { PathStyleExtension } from '@deck.gl/extensions/typed';
import { getCurrentColor } from '../../../utils';
import { GouletSegmentFeature } from '../../../models/GouletSegmentFeature';

const useGouletPathLayer = (): Array<PathLayer<SegmentProperties>> => {
  const visibleSegments = useAppSelector(selectGouletSegmentsByZone);

  const paths_1 = new PathLayer({
    id: 'path-goulet-segment',
    data: visibleSegments,
    getPath: (d: GouletSegmentFeature) => d.path as any,
    getWidth: (d: GouletSegmentFeature) => d.width,
    widthUnits : 'pixels',
    getColor: (d: GouletSegmentFeature) => getCurrentColor(Number(d.properties.etat)),
    getDashArray: () => [6, 4], // Set dash array for the dashed line pattern
    dashJustified: true, // Adjust the rendering of the dashes to align with path ends
    extensions: [new PathStyleExtension({ dash: false })],
    capRounded: false, // Rounded end of lines
    jointRounded: true, // Rounded joints between segments
  });

  return [paths_1];
};

export default useGouletPathLayer;
