import React from 'react';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';

import Line400kV from '../../assets/images/Line400kV.svg';
import ligne400Sat from '../../assets/images/goulet/ligne400Sat.svg';
import Eye from '../../assets/images/eye.svg';
import EyeClose from '../../assets/images/eye-close.svg';
import Conso from '../../assets/images/goulet/conso.svg';
import Exchange from '../../assets/images/goulet/exchange.svg';
import FluxPrincipal from '../../assets/images/goulet/fluxPrincipal.svg';
import ProdNucleaire from '../../assets/images/goulet/prodNucleaire.svg';
import ProdSoliare from '../../assets/images/goulet/prodSoliare.svg';
import ProdEolien from '../../assets/images/goulet/prodEolien.svg';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  selectStateClimate,
  selectToggleFluxPrincipal,
  toggleFluxPrincipal,
} from '../../store/mainReducer';
import ItemLegend from '../initMap/map-area/legend/ItemLegend';
import {
  LEGEND_COLORS,
  LEGEND_LABELS,
  SEGMENT_LABELS,
} from '../../utils/enums';
import ProductionItem from '../initMap/map-area/legend/ProductionItem';

const StyledDiv = styled.div`
  color: rgba(255, 255, 255, 1);
  background-color: rgba(10, 90, 114, 1);
  border: none;
  margin-bottom: 1rem;
  border-radius: 0.6rem;
  padding-left: 0.5rem;
  min-height: 25rem;
  .icon {
    width: 1.4rem;
  }
  .info {
    font-size: 0.9rem;
  }
`;

const ItemLegendGoulet = () => {
  const dispatch = useAppDispatch();
  const active = useAppSelector(selectToggleFluxPrincipal);
  const stateClimate = useAppSelector(selectStateClimate);
  const handleClick = () => {
    dispatch(toggleFluxPrincipal());
  };

  return (
    <StyledDiv>
      <div className="col-12  mb-1 mt-3">
        <h4>{'Légende :'}</h4>
      </div>
      <div className="row">
      <div className="col-10 fs-6 mb-1">{'Réseau'}</div>
        <div className="col-12">
          <ItemLegend title={SEGMENT_LABELS.LINE_400KV} src={Line400kV} />
        </div>
        <div className="col-12 fs-6">{'Etat des lignes'}</div>
        <div className="col-12">
          <ProductionItem
            color={LEGEND_COLORS.PROD_SEUIL3}
            label={LEGEND_LABELS.PROD_SEUIL3}
          />
        </div>
        <div className="col-12">
          <ProductionItem
            color={LEGEND_COLORS.PROD_SEUIL4}
            label={LEGEND_LABELS.PROD_SEUIL4}
          />
        </div>
        <div className="col-12">
          <ProductionItem
            color={LEGEND_COLORS.PROD_SEUIL5}
            label={LEGEND_LABELS.PROD_SEUIL5}
          />
        </div>
        <div className="col-12">
          <ProductionItem
            color={LEGEND_COLORS.PROD_SEUIL6}
            label={LEGEND_LABELS.PROD_SEUIL6}
          />
        </div>
      </div>
      <Row className="align-items-center mb-2">
        <div className="col-2">
          <img className="icon" src={FluxPrincipal} alt={''} />
        </div>
        <div className="col-10">
          <div className="row">
            <div
              onClick={handleClick}
              style={{ cursor: 'pointer' }}
              className="col-12"
            >
              <div className="row pt-3 pb-2">
                <div className="col-10 fs-6">{'Flux principaux'}</div>
                <div className="col-2 text-start">
                  <img src={active ? Eye : EyeClose} alt="Eye" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <Row className="align-items-center mb-2">
        <div className="col-12">
          <div className="align-items-center info">
            {'Inducteurs de contraintes'}
          </div>
        </div>
      </Row>
      <Row className="align-items-center mb-2">
        <div className="col-2">
          <img className="icon" src={ProdNucleaire} alt={''} />
        </div>
        <div className="col-10">
          <div className="align-items-center info">
            {'Production nucléaire'}
          </div>
        </div>
      </Row>
      {stateClimate === 'EJCS' ? (
        <Row className="align-items-center mb-2">
          <div className="col-2">
            <img className="icon" src={ProdSoliare} alt={''} />
          </div>
          <div className="col-10">
            <div className="align-items-center info">
              {'Production solaire (diffus)'}
            </div>
          </div>
        </Row>
      ) : (
        <Row className="align-items-center mb-2">
          <div className="col-2">
            <img className="icon" src={ProdEolien} alt={''} />
          </div>
          <div className="col-10">
            <div className="align-items-center info">
              {'Production éolienne (diffus)'}
            </div>
          </div>
        </Row>
      )}

      <Row className="align-items-center mb-2">
        <div className="col-2">
          <img className="icon" src={Conso} alt={''} />
        </div>
        <div className="col-10">
          <div className="align-items-center info">{'Consommation'}</div>
        </div>
      </Row>
      <Row className="align-items-center mb-2">
        <div className="col-2">
          <img className="icon" src={Exchange} alt={''} />
        </div>
        <div className="col-10">
          <div className="align-items-center info">
            {'Échange international'}
          </div>
        </div>
      </Row>
    </StyledDiv>
  );
};

export default ItemLegendGoulet;
