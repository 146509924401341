import { useAppSelector } from '../../../store/hooks';
import { selectCities, selectCurrentPointActive } from '../../../store/mainReducer';

import { TextLayer } from '@deck.gl/layers/typed';

import { CitiesFeature } from '../../../models/CitiesFeature';


const useCitiesLayer = () => {
  const cities = useAppSelector(selectCities);
  const currentPointActive = useAppSelector(selectCurrentPointActive);
  const namePostPoint = new TextLayer({
    id: 'post-point-layer',
    data: cities,
    getText: (d: CitiesFeature) => d.properties.NOM,
    getPosition: (d) => d.position as any,
    getAlignmentBaseline: 'center',
    getColor: () => [216, 216, 216],
    getSize: 12,
    fontWeight: 'normal',
    getTextAnchor: 'middle',
    background: false,
    getPixelOffset: [30, -20],
    characterSet: 'auto',
    outlineWidth: 4,
    getBorderWidth: 0,
    fontFamily: 'Nunito Sans',
    billboard: true,
    visible: true,
  });


  return [namePostPoint];
};

export default useCitiesLayer;
