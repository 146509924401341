import { LineString, Polygon, Point } from 'geojson';
import { SegmentFeature, SegmentProperties } from '../models/SegmentFeature';
import { LOOP_TRIPS_LENGTH } from '../utils/constants';
import {
  getCurrentColor,
  getLignePointColor,
  getLignePointSize,
  getIconePointColor,
} from '../utils';
import { SiteFeature, SiteProperties, TravauxPointsFeature, TravauxPointsProperties } from '../models/SiteFeature';
import {
  GouletFeature,
  GouletProperties,
} from '../models/GouletFeature';
import {
  CadrePointFeature,
  CadrePointProperties,
  ConsoPolygonFeature,
  FuseauPointProperties,
  FuseauPolygonFeature,
  IconePointFeature,
  IconePointProperties,
  LignePointFeature,
  LignePointProperties,
  PostTPointFeature,
  PostTPointProperties,
  ZoomPointFeature,
  ZoomPointProperties,
} from '../models/ZoomFeature';
// Start Sites
import SITES_2024_EJCS from '../data/sites/2024_IMAGRID_AREF_EJCS.json';
import SITES_RF_2024_GLOBAL_V3 from '../data/sites/SITES_RF_2024_GLOBAL_V5.json';
import SITES_RF_2030_GLOBAL_V4 from '../data/sites/SITES_RF_2030_GLOBAL_V5.json';
import SITES_RF_2035_GLOBAL_V4 from '../data/sites/SITES_RF_2035_GLOBAL_V5.json';
import SITES_RF_2040_GLOBAL_V4 from '../data/sites/SITES_RF_2040_GLOBAL_V5.json';
import SITES_2024_HNVC from '../data/sites/2024_IMAGRID_AREF_HNVC.json';
// End Sites
// Start B-ref
import SITES_2030_EJCS_REFB from '../data/sites/SITES_HISTB_2030_EJCS_V1.json';
import SITES_2030_HNVC_REFB from '../data/sites/SITES_HISTB_2030_HNVC_V1.json';
import SITES_2035_EJCS_REFB from '../data/sites/SITES_HISTB_2035_EJCS_V1.json';
import SITES_2035_HNVC_REFB from '../data/sites/SITES_HISTB_2035_HNVC_V1.json';
import SITES_2040_EJCS_REFB from '../data/sites/SITES_HISTB_2040_EJCS_V1.json';
import SITES_2040_HNVC_REFB from '../data/sites/SITES_HISTB_2040_HNVC_V1.json';
// End B-ref

// LIGNES MIX
import LINES_2024 from '../data/lignes/LINES_2024_V1.json';
import LINES_2030 from '../data/lignes/LINES_2030_V1.json';
import LINES_2035 from '../data/lignes/LINES_2035_V1.json';
import LINES_2040 from '../data/lignes/LINES_2040_V1.json';



import dataProd_REF_B_2024 from '../data/prodSidebar/dataProd_REF_B_2024.json';
import dataProd_REF_B_2030 from '../data/prodSidebar/dataProd_REF_B_2030.json';
import dataProd_REF_B_2035 from '../data/prodSidebar/dataProd_REF_B_2035.json';
import dataProd_REF_B_2040 from '../data/prodSidebar/dataProd_REF_B_2040.json';

/**** GOULET *** */
// LIGNES WITH DAY CLIMAT
import GOULET_LINES_2030_HNVC from '../data/goulet/lignes/2030_HNVC.json';
import GOULET_LINES_2030_EJCS from '../data/goulet/lignes/2030_EJCS.json';
import GOULET_LINES_2035_HNVC from '../data/goulet/lignes/2035_HNVC.json';
import GOULET_LINES_2035_EJCS from '../data/goulet/lignes/2035_EJCS.json';
import GOULET_LINES_2040_HNVC from '../data/goulet/lignes/2040_HNVC.json';
import GOULET_LINES_2040_EJCS from '../data/goulet/lignes/2040_EJCS.json';


import DATA_GOULET from '../data/goulet/ZONES_GOULETS_V2.json';

import DATA_ZOOM_POINTS from '../data/goulet/ZIPE_PTS_V4.json';

//******* POIN IN********** */
import data_init_stat from '../data/points/data_init_stat.json';
import ICONES_ZONE_FOS from '../data/points/ICONES_ZONE_FOS_V1.json';
import LIGNES_ZONE_FOS from '../data/points/LIGNES_ZONE_FOS_V1.json';
import CADRES_ZONE_FOS from '../data/points/CADRES_ZONE_FOS_V1.json';
import POSTES_ZONE_FOS from '../data/points/POSTES_ZONE_FOS_V1.json';
import CONSO_ZONE_FOS from '../data/points/CONSO_ZONE_FOS_V1.json';
import FUSEAU_ZONE_FOS from '../data/points/FUSEAU_ZONE_FOS_V1.json';

import ICONES_ZONE_HAVRE from '../data/points/ICONES_ZONE_HAVRE_V1.json';
import LIGNES_ZONE_HAVRE from '../data/points/LIGNES_ZONE_HAVRE_V1.json';
import POSTES_ZONE_HAVRE from '../data/points/POSTES_ZONE_HAVRE_V1.json';
import CONSO_ZONE_HAVRE from '../data/points/CONSO_ZONE_HAVRE_V1.json';
import VILLES_INTEREST_POINTS_SSFOS from '../data/points/VILLES_ZONE_FOS.json';
import VILLES_INTEREST_POINTS_SSHAVRE from '../data/points/VILLES_ZONE_HAVRE_V1.json';

import ICONES_GOULETS_V0 from '../data/goulet/ICONES_GOULETS_V0.json';

import {
  GouletSymbolFeature,
  GouletSymbolProperties,
} from '../models/GouletSymbolFeature';
import { CURRENT_YEAR_VALUES } from '../utils/enums';
import TRAVAUX_POINTS from '../data/ICONES_TRAVAUX.json';
import { CitiesFeature } from '../models/CitiesFeature';
import { GouletSegmentFeature } from '../models/GouletSegmentFeature';
// Start processing segments goulet, these segments depend on the current year and current day
// currentDay have two values: EJCS or HNVC
// currentYear have three values: 2030, 2035 or 2040
// Map to associate keys with datasets
const DATA_MAP: { [key: string]: any } = {
  'YEAR_2030_HNVC': GOULET_LINES_2030_HNVC,
  'YEAR_2030_EJCS': GOULET_LINES_2030_EJCS,
  'YEAR_2035_HNVC': GOULET_LINES_2035_HNVC,
  'YEAR_2035_EJCS': GOULET_LINES_2035_EJCS,
  'YEAR_2040_HNVC': GOULET_LINES_2040_HNVC,
  'YEAR_2040_EJCS': GOULET_LINES_2040_EJCS,
  'CITIES_INTEREST_POINTS_SSFOS': VILLES_INTEREST_POINTS_SSFOS,
  'CITIES_INTEREST_POINTS_SSHAVRE': VILLES_INTEREST_POINTS_SSHAVRE,
};


const getFiltredGouletSegments = (year: string, day: string): any => {
  // Create the key dynamically using year and day
  const key = `${year}_${day}`;
  const data = DATA_MAP[key];
  if (!data) return []; // Return empty array if data not found
  // return processGouletSegmentsData(data, currentGouletZone);
  return data;
};
export const getGouletSegments = (year: string, day: string): GouletSegmentFeature => {
  const segments = getFiltredGouletSegments(year, day);
  const data_segment = segments.features
    .map(
      (elt: any): any => {
        return {
          properties: { ...elt.properties },
          path: elt.geometry.coordinates,
          timestamps: [0, LOOP_TRIPS_LENGTH],
          width:
            elt.properties.epaisseur === 1
              ? elt.properties.epaisseur
              : elt.properties.epaisseur * 1.5,
        };
      },
    );
  return data_segment;
};
// End handling segmants
export const getCities = (zone: string): CitiesFeature[] => {
  const key = `CITIES_INTEREST_POINTS_${zone}`;
  const data = DATA_MAP[key];
  if (!data) return [];
  return (data as any).features.map((elt: any) => {
    return {
      position: elt.geometry.coordinates,
      properties: elt.properties
    }
  });
};

const getLignesByYear = (year: string): any => {
  if (year === 'YEAR_2024') {
    return LINES_2024;
  } else if (['YEAR_2030', 'YEAR_ADAPT_2030'].includes(year)) {
    console.log(year)
    return LINES_2030;
  } else if (year === 'YEAR_2035') {
    return LINES_2035;
  } else if (year === 'YEAR_2040') {
    return LINES_2040;
  }
};



export const getDataSiteByYear = (year: string): any => {
  switch (year) {
    case 'YEAR_2024':
      return SITES_RF_2024_GLOBAL_V3;
    case 'YEAR_2030':
      return SITES_RF_2030_GLOBAL_V4;
    case 'YEAR_ADAPT_2030':
      return SITES_RF_2030_GLOBAL_V4;
    case 'YEAR_2035':
      return SITES_RF_2035_GLOBAL_V4;
    case 'YEAR_2040':
      return SITES_RF_2040_GLOBAL_V4;
  }
};

export const getDataSiteByDay = (day: string, year: string): any => {
  if (year === 'YEAR_2024') {
    switch (day) {
      case 'EJCS':
        return SITES_2024_EJCS;
      case 'HNVC':
        return SITES_2024_HNVC;
    }
  }
  if (year === 'YEAR_2030') {
    switch (day) {
      case 'EJCS':
        return SITES_2030_EJCS_REFB;
      case 'HNVC':
        return SITES_2030_HNVC_REFB;
    }
  }
  if (year === 'YEAR_2035') {
    switch (day) {
      case 'EJCS':
        return SITES_2035_EJCS_REFB;
      case 'HNVC':
        return SITES_2035_HNVC_REFB;
    }
  }
  if (year === 'YEAR_2040') {
    switch (day) {
      case 'EJCS':
        return SITES_2040_EJCS_REFB;
      case 'HNVC':
        return SITES_2040_HNVC_REFB;
    }
  }
};

export const getSegmentsByYear = (year: string): SegmentFeature[] => {
  const segments = getLignesByYear(year);
  const data_segment = segments.features
    .filter((elt: any) => elt.geometry !== null)
    .map(
      (elt: GeoJSON.Feature<LineString, SegmentProperties>): SegmentFeature => {
        return {
          properties: { ...elt.properties },
          path: elt.geometry.coordinates,
          width: elt.properties.epaisseur,
          color: getCurrentColor(Number(elt.properties.etat)),
        };
      },
    );
  return data_segment;
};



export const getSeuil = (conso: number) => {
  if (conso >= 450) {
    return 10;
  }
  if (conso < 450 && conso >= 340) {
    return 20;
  }
  return 30;
};

export const getSidebarProd = (year: string) => {
  switch (year) {
    case 'YEAR_2024':
      return dataProd_REF_B_2024;
    case 'YEAR_2030':
      return dataProd_REF_B_2030;
    case 'YEAR_ADAPT_2030':
      return dataProd_REF_B_2030;
    case 'YEAR_2035':
      return dataProd_REF_B_2035;
    case 'YEAR_2040':
      return dataProd_REF_B_2040;
  }
};

export const getSitesByYear = (year: string): SiteFeature => {
  const data_sites = getDataSiteByYear(year);
  const sites = (data_sites as any).features
    .filter((elt: any) => elt.geometry !== null)
    .map((elt: GeoJSON.Feature<Polygon, SiteProperties>): SiteFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
        size: 1000,
        visible: true,
        seuil: getSeuil(elt.properties.TOTAL_CONSO),
        conso: elt.properties.TOTAL_CONSO,
        prod: elt?.properties?.TOTAL_PROD ? elt?.properties?.TOTAL_PROD : 0,
        tooltip: {
          position: elt.geometry.coordinates,
          content: 'Détails:',
        },
      };
    });
  return sites;
};

export const getSitesByDay = (
  year: string,
  current_day: string,
): SiteFeature => {
  const data_sites = getDataSiteByDay(current_day, year);
  const sites = (data_sites as any).features
    .filter((elt: any) => elt.geometry !== null)
    .map((elt: GeoJSON.Feature<Polygon, SiteProperties>): SiteFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
        size: 1000,
        visible: true,
        seuil: getSeuil(elt.properties.TOTAL_CONSO),
        conso: elt.properties.TOTAL_CONSO,
        prod: elt?.properties?.TOTAL_PROD ? elt?.properties?.TOTAL_PROD : 0,
        tooltip: {
          position: elt.geometry.coordinates,
          content: 'Détails:',
        },
      };
    });
  return sites;
};

export const getGoulet = (): GouletFeature => {
  const goulets = (DATA_GOULET as any).features.map(
    (elt: GeoJSON.Feature<Polygon, GouletProperties>): GouletFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
      };
    },
  );
  return goulets;
};

export const getZoomPoints = (): ZoomPointFeature => {
  const goulets = (DATA_ZOOM_POINTS as any).features.map(
    (elt: GeoJSON.Feature<Point, ZoomPointProperties>): ZoomPointFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
      };
    },
  );
  return goulets;
};

//********* POINT IN  *********** */
export const getIconesByPointIn = (zone: string): any => {
  switch (zone) {
    case 'SSFOS':
      return ICONES_ZONE_FOS;
    case 'SSHAVRE':
      return ICONES_ZONE_HAVRE;
  }
};

export const getLignesByPointIn = (zone: string): any => {
  switch (zone) {
    case 'SSFOS':
      return LIGNES_ZONE_FOS;
    case 'SSHAVRE':
      return LIGNES_ZONE_HAVRE;
  }
};

export const getPOSTByPointIn = (zone: string): any => {
  switch (zone) {
    case 'SSFOS':
      return POSTES_ZONE_FOS;
    case 'SSHAVRE':
      return POSTES_ZONE_HAVRE;
  }
};

export const getConsoByPointIn = (zone: string): any => {
  switch (zone) {
    case 'SSFOS':
      return CONSO_ZONE_FOS;
    case 'SSHAVRE':
      return CONSO_ZONE_HAVRE;
  }
};

export const getInitStatView = (zone: string, currentYear: string): any => {
  return data_init_stat.filter(
    (item) =>
      item.zone === zone &&
      item.year ===
        CURRENT_YEAR_VALUES[currentYear as keyof typeof CURRENT_YEAR_VALUES],
  )[0];
};

export const getCadrePointsIn = (): CadrePointFeature => {
  const list = (CADRES_ZONE_FOS as any).features.map(
    (
      elt: GeoJSON.Feature<Polygon, CadrePointProperties>,
    ): CadrePointFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
      };
    },
  );
  return list;
};

export const getIconesPointsIn = (
  zone: string,
  year: string,
): IconePointFeature => {
  const data = getIconesByPointIn(zone);
  const list = data.features
    .filter((elt: any) => elt.properties.ANNEE === year)
    .map(
      (
        elt: GeoJSON.Feature<Point, IconePointProperties>,
      ): IconePointFeature => {
        return {
          properties: { ...elt.properties },
          position: elt.geometry.coordinates,
          type: elt.properties.SOUS_TYPE,
          year: elt.properties.ANNEE,
          click: elt.properties.CLICK,
          variable: elt.properties.VARIABLE,
          color: getIconePointColor(elt.properties.SOUS_TYPE),
        };
      },
    );
  return list;
};

export const getLignesPoints = (
  zone: string,
  year: string,
): LignePointFeature => {
  const data = getLignesByPointIn(zone);
  const paths = data.features
    .filter((elt: any) => elt.properties.ZONE === zone)
    .map(
      (
        elt: GeoJSON.Feature<LineString, LignePointProperties>,
      ): LignePointFeature => {
        return {
          properties: { ...elt.properties },
          position: elt.geometry.coordinates,
          size: getLignePointSize(elt.properties.STYLE),
          color: getLignePointColor(elt.properties.STYLE),
          style: elt.properties.STYLE,
          year: elt.properties.ANNEE,
          click: elt.properties.CLICK,
        };
      },
    );

  return paths;
};

export const getPostsPointsIn = (
  zone: string,
  year: string,
): PostTPointFeature => {
  const data = getPOSTByPointIn(zone);
  const list = data.features
    .filter((elt: any) => elt.properties.ANNEE === year)
    .map(
      (
        elt: GeoJSON.Feature<Point, PostTPointProperties>,
      ): PostTPointFeature => {
        return {
          properties: { ...elt.properties },
          position: elt.geometry.coordinates,
          positionName: [Number(elt.properties.COORD_ETIQUETTE_X), Number(elt.properties.COORD_ETIQUETTE_Y)]
        };
      },
    );
  return list;
};

export const getGouletSymbols = (): GouletSymbolFeature => {
  const points = (ICONES_GOULETS_V0 as any).features.map(
    (
      elt: GeoJSON.Feature<Point, GouletSymbolProperties>,
    ): GouletSymbolFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
      };
    },
  );
  return points;
};

export const getConsoPolygons = (
  zone: string,
  year: string,
): ConsoPolygonFeature => {
  const data = getConsoByPointIn(zone);
  const list = data.features.map(
    (
      elt: GeoJSON.Feature<Polygon, CadrePointProperties>,
    ): ConsoPolygonFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
      };
    },
  );
  return list;
};
export const getFuseauPolygons = (): FuseauPolygonFeature => {
  const goulets = (FUSEAU_ZONE_FOS as any).features.map(
    (
      elt: GeoJSON.Feature<Polygon, FuseauPointProperties>,
    ): FuseauPolygonFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
      };
    },
  );
  return goulets;
};

export const getTravauxPoints = (): TravauxPointsFeature => {
  const goulets = (TRAVAUX_POINTS as any).features.map(
    (elt: GeoJSON.Feature<Point, TravauxPointsProperties>): TravauxPointsFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
      };
    },
  );
  return goulets;
};