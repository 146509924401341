export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoidGFib3VhbWluZSIsImEiOiJjbHJ0Y2llbW8wMG1pMmtvNmpxajlmZjFxIn0.rxU8U4iQ3JmsRZ9dfXnfeg';
export const LOOP_TRIPS_LENGTH = 1000;

export const METEO_REF_B_LABELS = [
  {
    name: 'Journée d’été',
    value: 'EJCS',
    disabled: false,
  },
  { name: 'Nuit d’hiver', value: 'HNVC', disabled: false },
];

export const YEARS_A_REF = [2024, 2030];
export const YEARS_B_REF = [2024, 2030, 2035, 2040];
