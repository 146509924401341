import { IconLayer } from '@deck.gl/layers/typed';

import {
  selectCurrentGouletZone,
  selectCurrentYear,
  selectGouletSymbols,
  selectStateClimate,
} from '../../../store/mainReducer';
import { useAppSelector } from '../../../store/hooks';
import { GouletSymbolFeature } from '../../../models/GouletSymbolFeature';
import { getSizeIcon, splitYear } from '../../../utils';

import IconsMap from '../../../assets/images/goulet/IconsMap.png';



const useConsoGouletLayer = () => {

  // Define icon mappings for normal and hover states
const iconMapping: any = {
  B_2030_EJCS_conso_1: { x: 284, y: 1107, width: 136, height: 118, mask: false, anchorX : 68, anchorY : 60 },
  B_2035_EJCS_conso_1: { x: 479, y: 1102, width: 136, height: 118, mask: false, anchorX : 68, anchorY : 60 },
  B_2040_EJCS_conso_1: { x: 705, y: 1098, width: 136, height: 118, mask: false, anchorX : 68, anchorY : 60 },
  
  B_2030_EJCS_conso_2: { x: 250, y: 1440, width: 222, height: 142, mask: false, anchorX : 124, anchorY : 80 },
  B_2035_EJCS_conso_2: { x: 248, y: 1445, width: 222, height: 142, mask: false, anchorX : 124, anchorY : 80 },
  B_2040_EJCS_conso_2: { x: 499.92, y: 1436, width: 222, height: 142, mask: false, anchorX : 124, anchorY : 80 },

  B_2030_HNVC_conso_1: { x: 903, y: 1095, width: 136, height: 118, mask: false, anchorX : 70, anchorY : 52 },
  B_2035_HNVC_conso_1: { x: 1081, y: 1091, width: 136, height: 118, mask: false, anchorX : 70, anchorY : 52},
  B_2040_HNVC_conso_1: { x: 1248, y: 1091, width: 136, height: 118, mask: false, anchorX : 70, anchorY : 52 },
  
  B_2030_HNVC_conso_2: { x: 770, y: 1430, width: 222, height: 142, mask: false, anchorX : 124, anchorY : 80 },
  B_2035_HNVC_conso_2: { x: 1048, y: 1430, width: 222, height: 142, mask: false, anchorX : 124, anchorY : 80 },
  B_2040_HNVC_conso_2: { x: 1310, y: 1428, width: 222, height: 142, mask: false, anchorX : 124, anchorY : 80 },

  C_2030_EJCS_conso_1: { x: 284, y: 1107, width: 136, height: 118, mask: false, anchorX : 68, anchorY : 60 },
  C_2035_EJCS_conso_1: { x: 479, y: 1102, width: 136, height: 118, mask: false, anchorX : 68, anchorY : 60 },
  C_2040_EJCS_conso_1: { x: 705, y: 1098, width: 136, height: 118, mask: false, anchorX : 68, anchorY : 60 },
  
  C_2030_EJCS_conso_2: { x: 250, y: 1440, width: 222, height: 142, mask: false,anchorX : 124, anchorY : 80 },
  C_2035_EJCS_conso_2: { x: 248, y: 1445, width: 222, height: 142, mask: false, anchorX : 124, anchorY : 80 },
  C_2040_EJCS_conso_2: { x: 499.92, y: 1436, width: 222, height: 142, mask: false, anchorX : 124, anchorY : 80 },

  C_2030_EJCS_conso_3: { x: 2, y: 1230, width: 165, height: 151, mask: false, anchorX : 89, anchorY : 80 },
  C_2035_EJCS_conso_3: { x: 150, y: 1240, width: 165, height: 150, mask: false, anchorX : 89, anchorY : 80 },
  C_2040_EJCS_conso_3: { x: 290, y: 1254, width: 165, height: 151, mask: false, anchorX : 110, anchorY : 70 },

  E_2030_EJCS_conso_1: { x: 5, y: 1230, width: 160, height: 151, mask: false, anchorX : 90, anchorY : 80 },
  E_2035_EJCS_conso_1: { x: 150, y: 1240, width: 165, height: 150, mask: false, anchorX : 95, anchorY : 80 },
  E_2040_EJCS_conso_1: { x: 290, y: 1254, width: 165, height: 151, mask: false, anchorX : 110, anchorY : 75 },

  E_2030_HNVC_conso_1: { x: 470, y: 1220, width: 160, height: 151, mask: false, anchorX : 89, anchorY : 110 },
  E_2035_HNVC_conso_1: { x: 620, y: 1240, width: 165, height: 150, mask: false, anchorX : 89, anchorY : 90 },
  E_2040_HNVC_conso_1: { x: 785, y: 1254, width: 165, height: 151, mask: false, anchorX : 89, anchorY : 78 },

  E_2030_EJCS_conso_2: { x: 5, y: 1570, width: 140, height: 134, mask: false, anchorX : 89, anchorY : 80 },
  E_2035_EJCS_conso_2: { x: 150, y: 1570, width: 140, height: 134, mask: false, anchorX : 90, anchorY : 80 },
  E_2040_EJCS_conso_2: { x: 279, y: 1575, width: 140, height: 134, mask: false, anchorX : 90, anchorY : 80 },

  E_2030_HNVC_conso_2: { x: 472, y: 1570, width: 140, height: 134, mask: false, anchorX : 40, anchorY : 60 },
  E_2035_HNVC_conso_2: { x: 617, y: 1580, width: 140, height: 134, mask: false, anchorX : 40, anchorY : 60 },
  E_2040_HNVC_conso_2: { x: 775, y: 1575, width: 140, height: 134, mask: false, anchorX : 40, anchorY : 60 },

  I_2030_EJCS_conso_1: { x: 2, y: 1700, width: 60, height: 54, mask: false, anchorX : 60, anchorY : 49 },
  I_2035_EJCS_conso_1: { x: 66, y: 1710, width: 79, height: 55, mask: false, anchorX : 60, anchorY : 38 },
  I_2040_EJCS_conso_1: { x: 159, y: 1713, width: 78, height: 54, mask: false, anchorX : 60, anchorY : 36 },

  I_2030_EJCS_conso_2: { x: 2, y: 1770, width: 70, height: 93, mask: false, anchorX : 52, anchorY : 54 },
  I_2035_EJCS_conso_2: { x: 98, y: 1770, width: 70, height: 93, mask: false, anchorX : 52, anchorY : 54 },
  I_2040_EJCS_conso_2: { x: 197, y: 1790, width: 70, height: 93, mask: false, anchorX : 45, anchorY : 46 },

  I_2030_HNVC_conso_1: { x: 272, y: 1721, width: 78, height: 55, mask: false, anchorX : 45, anchorY : 25 },
  I_2035_HNVC_conso_1: { x: 362, y: 1721, width: 78, height: 55, mask: false, anchorX : 54, anchorY : 38 },
  I_2040_HNVC_conso_1: { x: 454, y: 1721, width: 78, height: 54, mask: false, anchorX : 54, anchorY : 32 },

  I_2030_HNVC_conso_2: { x: 289, y: 1793, width: 70, height: 93, mask: false, anchorX : 52, anchorY : 50 },
  I_2035_HNVC_conso_2: { x: 393, y: 1793, width: 70, height: 93, mask: false, anchorX : 45, anchorY : 38 },
  I_2040_HNVC_conso_2: { x: 477, y: 1793, width: 70, height: 93, mask: false, anchorX : 40, anchorY : 42 },
  
};

  const currentYear = useAppSelector(selectCurrentYear);
  const currentGouletZone = useAppSelector(selectCurrentGouletZone);
  const currentClimat = useAppSelector(selectStateClimate);
  const gouletSymbol = useAppSelector(selectGouletSymbols);

  const getSymbolData = () => {
    return gouletSymbol.filter((item: GouletSymbolFeature) => {
      const { categorie } = item.properties;
      const mix = splitYear(currentYear).toString();
      const prefix = `${currentGouletZone}_${mix}_${currentClimat}_conso`;
      return categorie.startsWith(prefix);
    });
  };

  const layer = new IconLayer({
    id: 'conso-layer',
    data: getSymbolData(),
    getPosition: (d: any) => {
      return d.position;
    },
    getIcon: (d: any) =>  d.properties.categorie,
    getColor: () => [255, 255, 255, 110],
    iconAtlas: IconsMap, // Your image containing all icons
    iconMapping,
    sizeUnits: 'pixels',
    getSize: (d: any) => getSizeIcon(d.properties.categorie, iconMapping),
    // getPixelOffset: (d: any) => getOffsetIcon(d.properties.categorie),
    pickable: true,
  });

  return [layer];
};

export default useConsoGouletLayer;
