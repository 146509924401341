import { useAppSelector } from '../../../store/hooks';
import {
  selectCategoryActivePointIn,
  selectIconePointIn,
} from '../../../store/mainReducer';
import { IconLayer, TextLayer } from '@deck.gl/layers/typed';
import Co2h2 from '../../../assets/images/points/co2h2.svg';
import Industrie from '../../../assets/images/points/industrie.svg';
import Notification from '../../../assets/images/points/notification.svg';
import Roquerousse from '../../../assets/images/points/Roquerousse.svg';
import PosteSature from '../../../assets/images/points/Sandouville_PIN.svg';
import Ecarburant from '../../../assets/images/points/ecarburant.svg';


const useIconesPointLayer = () => {
  const iconesPointIn = useAppSelector(selectIconePointIn);
  const categoryActivePointIn = useAppSelector(selectCategoryActivePointIn);

  const getIconeByType = (type: string): any => {
    switch (type) {
      case 'co2h2':
        return {
          url: Co2h2,
          width: 51,
          height: 50,
        };
      case 'industrie':
        return {
          url: Industrie,
          width: 51,
          height: 50,
        };
      case 'transfo':
        return {
          url: Roquerousse,
          width: 46,
          height: 65,
        };
      case 'postesature':
        return {
          url: PosteSature,
          width: 46,
          height: 65,
        };
        case 'ecarburant':
          return {
            url: Ecarburant,
            width: 50,
            height: 51,
          };
    }
  };

  const getOffsetIcone = (type: string): [number, number] => {
    switch (type) {
      case 'transfo':
        return [0, -40];
      case 'postesature':
        return [0, -40];
      default:
        return [0, 0];
    }
  };

  const layerIcone = new IconLayer({
    id: 'icones-layer',
    data: iconesPointIn.filter(
      (item: any) => item.properties.CLICK === categoryActivePointIn,
    ),
    getIcon: (d: any) => getIconeByType(d.properties.SOUS_TYPE),
    getSize: (d: any) => getIconeByType(d.properties.SOUS_TYPE).height,
    getPosition: (d: any) => {
      return d.position;
    },
    getPixelOffset: (d: any) => getOffsetIcone(d.properties.SOUS_TYPE),
    pickable: true,
  });

  // const iconeName = new TextLayer({
  //   id: 'name-layer-1',
  //   data: iconesPointIn.filter(
  //     (item: any) =>
  //       item.properties.CLICK === categoryActivePointIn &&
  //       item.properties.SOUS_TYPE !== 'transfo' &&
  //       item.properties.SOUS_TYPE !== 'postesature',
  //   ),
  //   getText: (d: any) => `${d.variable}`,
  //   getPosition: (d: any) => d.position as any,
  //   getAlignmentBaseline: 'center',
  //   getSize: 11,
  //   getColor: (d: any) => d.color,
  //   backgroundPadding: [4, 2, 4, 2],
  //   pickable: true,
  //   getTextAnchor: 'start',
  //   background: false,
  //   getPixelOffset: (d: any) => [15, -22],
  //   characterSet: 'auto',
  //   outlineWidth: 10,
  //   maxWidth: 30.0,
  //   wordBreak: 'break-word',
  //   getBorderWidth: 0,
  //   fontFamily: 'Nunito Sans',
  //   billboard: true,
  // });

  // const cercleIcone = new IconLayer({
  //   id: 'cercle-layer',
  //   data: iconesPointIn.filter(
  //     (item: any) =>
  //       item.properties.CLICK === categoryActivePointIn &&
  //       item.properties.SOUS_TYPE !== 'transfo' &&
  //       item.properties.SOUS_TYPE !== 'postesature',
  //   ),
  //   getIcon: (d: any) => ({
  //     url: Notification,
  //     width: 25,
  //     height: 17,
  //   }),
  //   getPosition: (d: any) => {
  //     return d.position;
  //   },
  //   getSize: (d) => 17,
  //   getPixelOffset: (d: any) => [18, -22],
  //   pickable: true,
  // });

  return [layerIcone];
};

export default useIconesPointLayer;
