import { useAppSelector } from '../../../store/hooks';
import {
  selectTravauxPoints,
} from '../../../store/mainReducer';
import { IconLayer } from '@deck.gl/layers/typed';
import Travaux from '../../../assets/images/travaux.svg';

const useTravauxLayer = () => {
    const travaux = useAppSelector(selectTravauxPoints);

  const layerPoint = new IconLayer({
    id: 'travaux-layer',
    data: travaux,
    getIcon: () => ({
      url: Travaux,
      width: 34,
      height: 34,
    }),
    getPosition: (d: any) => {
      return d.position;
    },
    sizeMinPixels: 34,
    pickable: true,
  });

  return [layerPoint];
};

export default useTravauxLayer;
