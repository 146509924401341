import React from 'react';
import { Row } from 'react-bootstrap';

import styled from 'styled-components';

const StyledRow = styled(Row)`
  .icon {
    width: 1.4rem;
  }
  .info {
    width: 0.95rem;
    position: relative;
    bottom: 1px;
  }
`;

interface ItemsTitleProps {
  title: string;
  src: string;
}
const ItemLegend: React.FC<ItemsTitleProps> = ({
  title,
  src
}) => {
  return (
    <StyledRow className="align-items-center mb-2 fs-6">
      <div className="col-2">
        <img className="icon" src={src} alt={title} />
      </div>
      <div className="col-8">
        <div className="row align-items-center">
          <div className="col-auto">{title}</div>
        </div>
      </div>
    </StyledRow>
  );
};

export default ItemLegend;
