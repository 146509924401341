import React, { useState } from 'react';
import DeckGL from '@deck.gl/react';
import Map from 'react-map-gl';
import MapStyleHavre from '../../assets/map-styles/interest-havre-map-style.json';
import MapStyleFos from '../../assets/map-styles/interest-fos-map-style.json';
import { fromJS } from 'immutable';

import styled from 'styled-components';
import { ZoomPointFeature } from '../../models/ZoomFeature';
import RightPointComponent from './RightPointComponent';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import ContentPointInComponent from './ContentPointInComponent';
import {
  selectAdaptationPointIn,
  selectCurrentPointActive,
  selectCurrentYear,
  selectInitStateViewPointIn,
  setCadreActivePointIn,
  setCategoryActivePointIn,
  setConsoPolygons,
  setFuseauPolygons,
  setCurrentYear,
  setElementHeightPointIn,
  setIconePointIn,
  setInitStateViewPointIn,
  setLignesPointIn,
  setPostPointIn,
  setCities,
} from '../../store/mainReducer';

import useLignesPointLayer from '../layers/poinIn/useLignesPointLayer';
import usePostPointLayer from '../layers/poinIn/usePostPointLayer';
import useIconesPointLayer from '../layers/poinIn/useIconesPointLayer';
import { MAPBOX_ACCESS_TOKEN } from '../../utils/constants';
import useCadrePointInLayer from '../layers/poinIn/useCadrePointInLayer';

import ItemLegendPoint from './ItemLegendPoint';
import AdaptationSwitcher from './AdaptationSwitcher';

import {
  getIconesPointsIn,
  getLignesPoints,
  getCadrePointsIn,
  getPostsPointsIn,
  getConsoPolygons,
  getInitStatView,
  getFuseauPolygons,
  getCities,
} from '../../services';
import useConsoLayer from '../layers/poinIn/useConsoLayer';
import useFuseauLayer from '../layers/poinIn/useFuseauLayer';
import { CURRENT_YEAR_VALUES } from '../../utils/enums';
import SliderPointIn from './SliderPointIn';
import useCitiesLayer from '../layers/poinIn/useCities';

interface ModalProps {
  currentZoom: ZoomPointFeature;
  handleClose: () => void;
}

const StyledDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1500;
`;

const PointInterestMap: React.FC<ModalProps> = ({
  currentZoom,
  handleClose,
}) => {
  const dispatch = useAppDispatch();
  const currentYear = useAppSelector(selectCurrentYear);
  const currentPointActive = useAppSelector(selectCurrentPointActive);
  const initStateViewPointIn = useAppSelector(selectInitStateViewPointIn);
  const adaptationPointIn = useAppSelector(selectAdaptationPointIn);

  const [viewState, setViewState] = useState(initStateViewPointIn);
  const [viewStateAdapt, setViewStateAdapt] = useState(initStateViewPointIn);

  const elementRef = React.useRef<any>(null); // Create a ref to the element

  const handleCloseModal = (): void => {
    handleClose();
  };

  const lignesPointLayer = useLignesPointLayer();
  const consoLayer = useConsoLayer();
  const fuseauLayer = useFuseauLayer();
  const cadrePointInLayer = useCadrePointInLayer();
  const iconesPointLayer = useIconesPointLayer();
  const postPointLayer = usePostPointLayer();
  const citiestLayer = useCitiesLayer();

  // ADD Layers
  const layers: any = [
    consoLayer,
    lignesPointLayer,
    cadrePointInLayer,
    iconesPointLayer,
    fuseauLayer,
    postPointLayer,
    citiestLayer,
  ];

  const getMapStyle = (zone: string) => {
    switch (zone) {
      case 'SSFOS':
        return MapStyleHavre;
      case 'SSHAVRE':
        return MapStyleHavre;
    }
  };

  // UPDATE LAYERS AFTER CHANGE
  const setUpdateDataByZoom = (year: string) => {
    dispatch(setCategoryActivePointIn('pas de click'));
    const data_ligne = getLignesPoints(currentPointActive, year);
    dispatch(setLignesPointIn(data_ligne));

    const data_icones = getIconesPointsIn(currentPointActive, year);
    dispatch(setIconePointIn(data_icones));

    const data_post = getPostsPointsIn(currentPointActive, year);
    dispatch(setPostPointIn(data_post));

    const conso_polygons = getConsoPolygons(currentPointActive, year);
    dispatch(setConsoPolygons(conso_polygons));

    const cities = getCities(currentPointActive);
    dispatch(setCities(cities));

    if (currentPointActive === 'SSFOS') {
      const data_cadre = getCadrePointsIn();
      dispatch(setCadreActivePointIn(data_cadre));

      const fuseau_polygons = getFuseauPolygons();
      dispatch(setFuseauPolygons(fuseau_polygons));
    }
  };

  React.useEffect(() => {
    dispatch(setCurrentYear(currentYear));
    setUpdateDataByZoom(
      CURRENT_YEAR_VALUES[currentYear as keyof typeof CURRENT_YEAR_VALUES],
    );

    if (elementRef.current) {
      // Access the height of the element
      const elementHeight = elementRef.current.clientHeight;
      dispatch(setElementHeightPointIn(elementHeight)); // Set the height in state
    }
  }, [dispatch]); // Empty dependency array to run on mount

  // SLIDER BY ZOOM OUTPUT
  const handleZoomChange = (state: any, newYear: string) => {
    const longitude = state.longitude;
    const latitude = state.latitude;
    const zoom = state.zoom;

    setViewState((prevState: any) => ({
      ...prevState,
      zoom,
      latitude,
      longitude,
      transitionDuration: 600,
    }));

    dispatch(setInitStateViewPointIn(state));
    setUpdateDataByZoom(
      CURRENT_YEAR_VALUES[newYear as keyof typeof CURRENT_YEAR_VALUES],
    );
  };

  // ADAPTATION UPDATE OUTPUT
  const handleUpdateAdapt = (currentYear: any) => {
    const state = getInitStatView(currentPointActive, currentYear);
    setViewStateAdapt((prevState: any) => ({
      ...prevState,
      zoom: state.zoom,
      latitude: state.latitude,
      longitude: state.longitude,
      transitionDuration: 600,
    }));

    dispatch(setInitStateViewPointIn(state));
    setUpdateDataByZoom(
      CURRENT_YEAR_VALUES[currentYear as keyof typeof CURRENT_YEAR_VALUES],
    );
  };

  return (
    <StyledDiv>
      <div className="container-fluid">
        <div className="row vh-100-md p-10" id="content">
          <div
            className="col-12 order-1 order-md-0 col-md-2 d-none d-md-block sidebar"
            id="left-area"
          >
            <div
              className="row p-2 px-3 pr-3"
              style={{ position: 'relative', zIndex: 1500 }}
            >
              <div className="col-12 mt-2 mb-1">
                <span className="mb-0 subTitle">{'Point d’intérêt'}</span>
                <h5 className='fs-strong'>{`${currentPointActive==='SSHAVRE' ? 'Zone du Havre' : 'Zone de Fos-sur-Mer'}`}</h5>
              </div>
              <SliderPointIn handleUpdate={handleZoomChange} />

              {currentYear !== `YEAR_2024` && (
                <>
                  <div className="col-12 mt-2 mb-1">
                    <h5 className="mb-0">{'Réseau :'}</h5>
                  </div>
                  <AdaptationSwitcher handleUpdate={handleUpdateAdapt} />
                </>
              )}
              <ItemLegendPoint />
            </div>
          </div>
          <div
            className="col-12 order-0 order-md-1 col-md-8 vh-md-100 vh-sm-100 pe-2 ps-2 shadow-sm"
            id="center-area"
            ref={elementRef}
            style={{ height: '100vh', position: 'relative' }}
          >
            {!adaptationPointIn ? (
              <DeckGL
                layers={layers}
                initialViewState={viewState}
                controller={{
                  touchRotate: false, // Disable two-finger rotation
                  touchZoom: false, // Disable pinch-to-zoom
                  dragPan: false, // Disable touch drag
                  scrollZoom: false,
                  doubleClickZoom: false,
                  inertia: false,
                }}
                onViewStateChange={({ viewState }) => setViewState(viewState)}
              >
                <Map
                  mapStyle={fromJS(getMapStyle(currentPointActive)) as any}
                  mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                  touchPitch={false}
                  dragRotate={false}
                  dragPan={false}
                  touchZoomRotate={false}
                  doubleClickZoom={false}
                  scrollZoom={false}
                />
                <ContentPointInComponent handleClose={handleCloseModal} />
              </DeckGL>
            ) : (
              <DeckGL
                layers={layers}
                initialViewState={viewStateAdapt}
                controller={{
                  touchRotate: false, // Disable two-finger rotation
                  touchZoom: false, // Disable pinch-to-zoom
                  dragPan: false, // Disable touch drag
                  scrollZoom: false,
                  doubleClickZoom: false,
                  inertia: false,
                }}
              >
                <Map
                  reuseMaps
                  mapStyle={fromJS(getMapStyle(currentPointActive)) as any}
                  mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                  touchPitch={false}
                  dragRotate={false}
                  dragPan={false}
                  touchZoomRotate={false}
                  doubleClickZoom={false}
                  scrollZoom={false}
                />
                <ContentPointInComponent handleClose={handleCloseModal} />
              </DeckGL>
            )}
          </div>
          <div
            className="col-12 order-2 order-md-2 col-md-2 d-none d-md-block sidebar"
            id="right-area"
          >
            <RightPointComponent />
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

export default PointInterestMap;
