import { PolygonLayer } from '@deck.gl/layers/typed';
import { useAppSelector } from '../../../store/hooks';
import {
  selectCategoryActivePointIn,
  selectCurrentPointActive,
  selectCurrentYear,
  selectFuseauPolygons,
} from '../../../store/mainReducer';
import { PathStyleExtension } from '@deck.gl/extensions/typed';
import { CURRENT_YEAR_VALUES } from '../../../utils/enums';

const useFuseauLayer = () => {
  const currentPointActive = useAppSelector(selectCurrentPointActive);
  const currentYear = useAppSelector(selectCurrentYear);
  const categoryActivePointIn = useAppSelector(selectCategoryActivePointIn);
  const fuseauPolygons = useAppSelector(selectFuseauPolygons);
  
  const goulets = new PolygonLayer({
    id: `fuseau-1`,
    data: fuseauPolygons.filter((conso: any) => {
      return conso.properties.ANNEE === CURRENT_YEAR_VALUES[currentYear as keyof typeof CURRENT_YEAR_VALUES] && conso.properties.ZONE === currentPointActive && conso.properties.CLICK === categoryActivePointIn;
    }),
    getPolygon: (d) => d.position,
    getFillColor: (d) => [83, 222, 209],
    pickable: true,
    opacity: 0.2,

    lineWidthUnits: 'pixels',
    stroked: true,
    extruded: false,
    getLineColor: (d) => [83, 222, 209],
    filled: true, // Fills the polygon with color
    getLineWidth: 2,
    lineWidthMinPixels: 2,
    lineJointRounded: true,
    // PathStyleExtension specific settings
    getDashArray: () => [5, 5], // Set dash array for the dashed line pattern
    dashJustified: false,
    // dashGapPickable: false,
    extensions: [new PathStyleExtension({ highPrecisionDash: true })],
  });

  return [goulets];
};

export default useFuseauLayer;
