import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { SegmentFeature } from '../models/SegmentFeature';
import { ProdProperties, SiteFeature, TravauxPointsFeature } from '../models/SiteFeature';
import {
  GouletFeature,
} from '../models/GouletFeature';
import { CURRENT_DAY, CURRENT_YEAR, CURRENT_YEAR_VALUES } from '../utils/enums';
import {
  ConsoPolygonFeature,
  FuseauPolygonFeature,
  IconePointFeature,
  LignePointFeature,
  PostTPointFeature,
  ZoomInitStateFeature,
  ZoomPointFeature,
} from '../models/ZoomFeature';
import { GouletSymbolFeature } from '../models/GouletSymbolFeature';
import { CitiesFeature } from '../models/CitiesFeature';
import { GouletSegmentFeature } from '../models/GouletSegmentFeature';

interface ToggleMixElec {
  ligneCCHT: boolean;
  wind: boolean;
  photovoltaic: boolean;
  nuclear: boolean;
  thermic: boolean;
  hydraulic: boolean;
  line400kV: boolean;
  other: boolean;
}

interface ToggleModalActive {
  legend: boolean; // Réseau
  simulator: boolean;
}

const initToggleModalActive: ToggleModalActive = {
  legend: false,
  simulator: false,
};

const initialToggleMixElec: ToggleMixElec = {
  ligneCCHT: true,
  line400kV: true,
  wind: true,
  photovoltaic: true,
  nuclear: true,
  thermic: true,
  other: true,
  hydraulic: true,
};

interface MainState {
  currentYear: string;
  segments: SegmentFeature[];
  sites: SiteFeature[];
  goulets: {
    segments: GouletSegmentFeature[];
    polygons: GouletFeature[];
    currentGouletZone: string;
    grid: SiteFeature[];
    symbols: GouletSymbolFeature[];
    fluxPrincipal: boolean,
  };
  zoom: {
    zoomPoints: ZoomPointFeature[];
    currentZoom: ZoomPointFeature | null;
  };
  pointsIn: {
    currentPointActive: string;
    lignes: LignePointFeature[];
    iconePoints: IconePointFeature[];
    postPoints: PostTPointFeature[];
    cadreActivePoints: any[];
    elementHeight: number;
    initStateView: ZoomInitStateFeature;
    categoryActive: string;
    adaptation: boolean;
    consoPolygons: ConsoPolygonFeature[];
    fuseauPolygons: FuseauPolygonFeature[];
  };
  productions: ProdProperties[];
  toggleMixElec: ToggleMixElec;
  stateClimate: string;
  toggleModalActive: ToggleModalActive;
  toggleProd: boolean;
  consommation: boolean;
  travauxPoints: TravauxPointsFeature[];
  cities: CitiesFeature[];
}

const initialState: MainState = {
  currentYear: CURRENT_YEAR.YEAR_2024,
  segments: [],
  sites: [],
  goulets: {
    segments: [],
    polygons: [],
    currentGouletZone: '',
    grid: [],
    symbols: [],
    fluxPrincipal: true,
  },
  zoom: {
    zoomPoints: [],
    currentZoom: null,
  },
  pointsIn: {
    currentPointActive: '',
    lignes: [],
    iconePoints: [],
    postPoints: [],
    cadreActivePoints: [],
    elementHeight: 0,
    initStateView: {
      longitude: 5.366731073101268,
      latitude: 43.62213550368543,
      zoom: 8,
    },
    categoryActive: 'pas de click',
    adaptation: false,
    consoPolygons: [],
    fuseauPolygons: [],
  },
  productions: [],
  toggleProd: true,
  consommation: true,
  toggleMixElec: initialToggleMixElec,
  stateClimate: CURRENT_DAY.EJCS,
  toggleModalActive: initToggleModalActive,
  travauxPoints: [],
  cities: [],
};

export const mainSlice = createSlice({
  name: 'M',
  initialState,
  reducers: {
    setCurrentYear: (state, action) => {
      state.currentYear = action.payload;
    },
    setStateClimate: (state, action) => {
      state.stateClimate = action.payload;
    },
    toggleModalLegend: (state, action) => {
      state.toggleModalActive.legend = action.payload;
    },
    toggleModalSimulator: (state, action) => {
      state.toggleModalActive.simulator = action.payload;
    },
    setSegments: (state, action) => {
      state.segments = action.payload;
    },
    setSites: (state, action) => {
      state.sites = action.payload;
    },
    setGoulets: (state, action) => {
      state.goulets.polygons = action.payload;
    },
    setGouletSegments: (state, action) => {
      state.goulets.segments = action.payload;
    },
    setGouletSymbols: (state, action) => {
      state.goulets.symbols = action.payload;
    },
    setZoomPoints: (state, action) => {
      state.zoom.zoomPoints = action.payload;
    },
    setCurrentZoom: (state, action) => {
      state.zoom.currentZoom = action.payload;
    },
    setCurrentGouletZone: (state, action) => {
      state.goulets.currentGouletZone = action.payload;
    },
    setSidebarProductions: (state, action) => {
      state.productions = action.payload;
    },
    toggleProduction: (state) => {
      state.toggleProd = !state.toggleProd;
    },
    toggleConsommation: (state) => {
      state.consommation = !state.consommation;
    },
    toggleFluxPrincipal: (state) => {
      state.goulets.fluxPrincipal = !state.goulets.fluxPrincipal;
    },
    setCurrentPointActive: (state, action) => {
      state.pointsIn.currentPointActive = action.payload;
    },
    setCadreActivePointIn: (state, action) => {
      state.pointsIn.cadreActivePoints = action.payload;
    },
    setConsoPolygons: (state, action) => {
      state.pointsIn.consoPolygons = action.payload;
    },
    setFuseauPolygons: (state, action) => {
      state.pointsIn.fuseauPolygons = action.payload;
    },
    setLignesPointIn: (state, action) => {
      state.pointsIn.lignes = action.payload;
    },
    setPostPointIn: (state, action) => {
      state.pointsIn.postPoints = action.payload;
    },
    setIconePointIn: (state, action) => {
      state.pointsIn.iconePoints = action.payload;
    },
    setElementHeightPointIn: (state, action) => {
      state.pointsIn.elementHeight = action.payload;
    },
    setCategoryActivePointIn: (state, action) => {
      state.pointsIn.categoryActive = action.payload;
    },
    setInitStateViewPointIn: (state, action) => {
      state.pointsIn.initStateView = action.payload;
    },
    setAdaptationPointIn: (state, action) => {
      state.pointsIn.adaptation = action.payload;
    },
    setTravauxPoints: (state, action) => {
      state.travauxPoints = action.payload;
    },
    setCities: (state, action) => {
      state.cities = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCurrentYear,
  setCurrentPointActive,
  setLignesPointIn,
  setPostPointIn,
  setIconePointIn,
  setElementHeightPointIn,
  setStateClimate,
  toggleModalLegend,
  toggleModalSimulator,
  setSegments,
  setSites,
  setGoulets,
  setGouletSegments,
  setGouletSymbols,
  setZoomPoints,
  setCurrentGouletZone,
  setCurrentZoom,
  setSidebarProductions,
  toggleConsommation,
  toggleFluxPrincipal,
  toggleProduction,
  setInitStateViewPointIn,
  setCategoryActivePointIn,
  setCadreActivePointIn,
  setAdaptationPointIn,
  setConsoPolygons,
  setFuseauPolygons,
  setTravauxPoints,
  setCities,
} = mainSlice.actions;

export const selectStateClimate = (state: RootState) => state.main.stateClimate;
export const selectCurrentYear = (state: RootState) => state.main.currentYear;

export const selectModalLegend = (state: RootState) =>
  state.main.toggleModalActive.legend;

export const selectModalSimulator = (state: RootState) =>
  state.main.toggleModalActive.simulator;

export const selectSegments = (state: RootState) => state.main.segments;
export const selectSites = (state: RootState) => state.main.sites;
export const selectGoulets = (state: RootState) => state.main.goulets.polygons;
export const selectGouletSymbols = (state: RootState) =>
  state.main.goulets.symbols;
export const selectCurrentGouletZone = (state: RootState) =>
  state.main.goulets.currentGouletZone;
export const selectGouletsByYear = createSelector(
  [
    selectGoulets,
    selectCurrentYear,
    selectStateClimate,
    selectCurrentGouletZone,
  ],
  (goulets, currentYear, climate, gouletId) => {
    if (CURRENT_YEAR.YEAR_2024 === currentYear) {
      return [];
    }
    return gouletId === ''
      ? goulets
      : goulets.filter((g: any) => {
          return g.properties.zone === gouletId;
        });
  },
);

const selectGouletSegments = (state: RootState) => state.main.goulets.segments;
export const selectGouletSegmentsByZone = createSelector(
  [selectGouletSegments, selectCurrentGouletZone],
  (goulets, zone) => {
    const zoneKey = `zone_${zone.toLowerCase()}`;
    return goulets.map((item: any) => {
      if (item.properties[zoneKey] === false) {
        // Create a new object with updated properties
        return {
          ...item,
          properties: {
            ...item.properties,
            etat: -1,
            epaisseur: 2,
          },
        };
      }
      return item; // Return the original item if no changes are needed
    });
  },
);

const selectZoomPoints = (state: RootState) => state.main.zoom.zoomPoints;
export const selectZoomPointsByYear = createSelector(
  [selectZoomPoints, selectCurrentYear],
  (goulets, currentYear) => {
    if (CURRENT_YEAR.YEAR_2030 === currentYear) {
      return [];
    }
    return goulets;
  },
);

export const selectCurrentZoom = (state: RootState) =>
  state.main.zoom.currentZoom;
export const selectSidebarProductions = (state: RootState) =>
  state.main.productions;

export const selectToggleProduction = (state: RootState) =>
  state.main.toggleProd;

export const selectToggleConsommation = (state: RootState) =>
  state.main.consommation;
export const selectToggleFluxPrincipal = (state: RootState) =>
  state.main.goulets.fluxPrincipal;
export const selectCurrentPointActive = (state: RootState) =>
  state.main.pointsIn.currentPointActive;

export const selectLignesPointIn = (state: RootState) =>
  state.main.pointsIn.lignes;

export const selectIconePointIn = (state: RootState) =>
  state.main.pointsIn.iconePoints;

export const selectConsoPolygons = (state: RootState) =>
  state.main.pointsIn.consoPolygons;
export const selectFuseauPolygons = (state: RootState) =>
  state.main.pointsIn.fuseauPolygons;

export const selectElementHeightPointIn = (state: RootState) =>
  state.main.pointsIn.elementHeight;

export const selectCategoryActivePointIn = (state: RootState) =>
  state.main.pointsIn.categoryActive;
const selectPostPointIn = (state: RootState) => state.main.pointsIn.postPoints;
export const selectPostPointInByYearAndClick = createSelector(
  [selectPostPointIn, selectCurrentYear, selectCategoryActivePointIn],
  (points, currentYear, categoryActive) => {
    return points.filter(
      (item) =>
        item.properties.CLICK === categoryActive &&
        item.properties.ANNEE ===
          CURRENT_YEAR_VALUES[currentYear as keyof typeof CURRENT_YEAR_VALUES],
    );
  },
);

export const selectInitStateViewPointIn = (state: RootState) =>
  state.main.pointsIn.initStateView;

export const selectCadreActivePointIn = (state: RootState) =>
  state.main.pointsIn.cadreActivePoints;

export const selectAdaptationPointIn = (state: RootState) =>
  state.main.pointsIn.adaptation;
export const selectTravauxPoints = (state: RootState) =>
  state.main.travauxPoints;
export const selectCities = (state: RootState) =>
  state.main.cities;

export default mainSlice.reducer;
