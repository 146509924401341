import React from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  selectCurrentYear,
  selectStateClimate,
  setCurrentYear,
  setSidebarProductions,
  setSegments,
  setSites,
  selectCurrentGouletZone,
  setCurrentGouletZone,
  setGouletSegments,
} from '../store/mainReducer';
import {
  getGouletSegments,
  getSegmentsByYear,
  getSidebarProd,
  getSitesByDay,
  getSitesByYear,
} from '../services';
import { getCurrentYear, splitYear } from '../utils';
import { CURRENT_YEAR } from '../utils/enums';

interface YearsProps {
  years: any[];
}

const SliderByYear: React.FC<YearsProps> = ({ years }) => {
  const currentYear = useAppSelector(selectCurrentYear);
  const selectedClimate = useAppSelector(selectStateClimate);
  const currentGouletZone = useAppSelector(selectCurrentGouletZone);

  const dispatch = useAppDispatch();

  const [year, setYear] = React.useState(
    years[years.indexOf(splitYear(currentYear))],
  );

  // Function to handle change in slider
  const handleChange = (e: any) => {
    if (e.target.value) {
      const index = parseInt(e.target.value, 10); // Get the index from the slider
      setYear(years[index]); // Set the year based on the index

      const selectedYear = getCurrentYear(`YEAR_${years[index]}`);
      dispatch(setCurrentYear(selectedYear));

      if (currentGouletZone === '' || selectedYear !== CURRENT_YEAR.YEAR_2030) {
        const data_segment = getSegmentsByYear(selectedYear);
        dispatch(setSegments(data_segment));

        const sites = getSitesByYear(selectedYear);
        dispatch(setSites(sites));

        dispatch(setCurrentGouletZone(''));
      } else {
        const data_segment = getGouletSegments(selectedYear, selectedClimate);
        dispatch(setGouletSegments(data_segment));

        const sites = getSitesByDay(selectedYear, selectedClimate);
        dispatch(setSites(sites));
      }

      const productions = getSidebarProd(selectedYear);
      dispatch(setSidebarProductions(productions));
    }
  };

  return (
    <div className="slider">
      <div className="d-flex justify-content-between">
        {years.map((item: any, index: number) => {
          return (
            <small key={index} className="text-muted">
              {item}
            </small>
          );
        })}
      </div>
      <RangeSlider
        value={years.indexOf(year)} // Set the current value based on the index
        onChange={handleChange}
        min={0} // The min value is the first index
        max={years.length - 1} // The max value is the last index
        step={1} // Step by one index
        tooltip="off" // Disable the built-in tooltip, you can enable if you prefer
      />
    </div>
  );
};

export default SliderByYear;
