import { PolygonLayer } from '@deck.gl/layers/typed';
import { PathStyleExtension } from '@deck.gl/extensions/typed';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectCurrentGouletZone,
  selectCurrentYear,
  selectGouletsByYear,
  selectStateClimate,
  setCurrentGouletZone,
  setGouletSegments,
  setSites,
} from '../../../store/mainReducer';
import { getGouletSegments, getSitesByDay } from '../../../services';

const useGouletLayer = () => {

  const currentGouletZone = useAppSelector(selectCurrentGouletZone);
  const goulets_data = useAppSelector(selectGouletsByYear);

  const currentYear = useAppSelector(selectCurrentYear);
  const selectedClimate = useAppSelector(selectStateClimate);
  
  const dispatch = useAppDispatch();

  const handleClick = (d: any) => {
    if (currentGouletZone === '') {
      dispatch(setCurrentGouletZone(d.object.properties.zone));

      // update segement with climate
      const data_segment = getGouletSegments(currentYear, selectedClimate);
      dispatch(setGouletSegments(data_segment));

      const sites = getSitesByDay(currentYear, selectedClimate);
      dispatch(setSites(sites));
    }
    return;
  };

  const goulets = new PolygonLayer({
    id: `goulets`,
    data: goulets_data,
    getPolygon: (d) => d.position,
    lineWidthUnits: 'pixels',
    stroked: true,
    extruded: false,
    getFillColor: (d) => [255, 0, 0, 0.5],
    getLineColor: (d) => [220, 220, 220],
    filled: true, // Fills the polygon with color
    getLineWidth: 2,
    lineWidthMinPixels: 2,
    lineJointRounded: true,
    pickable: true,
    onClick: handleClick,
    autoHighlight: currentGouletZone === '',
    highlightColor: [255, 255, 255, 100],
    opacity: 1,
    // PathStyleExtension specific settings
    getDashArray: () => [10, 15], // Set dash array for the dashed line pattern
    dashJustified: false,
    // dashGapPickable: false,
    extensions: [new PathStyleExtension({ highPrecisionDash: true })],
  });

  return [goulets];
};

export default useGouletLayer;
