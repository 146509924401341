import { IconLayer } from '@deck.gl/layers/typed';

import {
  selectCurrentGouletZone,
  selectCurrentYear,
  selectGouletSymbols,
  selectStateClimate,
} from '../../../store/mainReducer';
import { useAppSelector } from '../../../store/hooks';
import { GouletSymbolFeature } from '../../../models/GouletSymbolFeature';
import { getSizeIcon, splitYear } from '../../../utils';

import IconsMap from '../../../assets/images/goulet/IconsMap.png';

// Define icon mappings for normal and hover states
const HNVC = {
  x: 1020,
  y: 1250,
  width: 36,
  height: 71,
  mask: false,
  anchorX: 12,
  anchorY: 38,
};
const EJCS = {
  x: 1099,
  y: 1250,
  width: 36,
  height: 71,
  mask: false,
  anchorX: 12,
  anchorY: 38,
};
const iconMapping: any = {
  B_2030_EJCS_echange_angleterre: EJCS,
  B_2035_EJCS_echange_angleterre: EJCS,
  B_2040_EJCS_echange_angleterre: EJCS,
  B_2030_HNVC_echange_angleterre: HNVC,
  B_2035_HNVC_echange_angleterre: HNVC,
  B_2040_HNVC_echange_angleterre: HNVC,
  C_2030_HNVC_echange_angleterre: HNVC,
  C_2035_HNVC_echange_angleterre: HNVC,
  C_2040_HNVC_echange_angleterre: HNVC,
  E_2030_HNVC_echange_angleterre: HNVC,
  E_2035_HNVC_echange_angleterre: HNVC,
  E_2040_HNVC_echange_angleterre: HNVC,
  I_2030_EJCS_echange_angleterre: EJCS,
  I_2035_EJCS_echange_angleterre: EJCS,
  I_2040_EJCS_echange_angleterre: EJCS,
  I_2030_HNVC_echange_angleterre: HNVC,
  I_2035_HNVC_echange_angleterre: HNVC,
  I_2040_HNVC_echange_angleterre: HNVC,
};

const useExchangeAngleterreGouletLayer = () => {
  const currentYear = useAppSelector(selectCurrentYear);
  const currentGouletZone = useAppSelector(selectCurrentGouletZone);
  const currentClimat = useAppSelector(selectStateClimate);
  const gouletSymbol = useAppSelector(selectGouletSymbols);

  const getSymbolData = () => {
    return gouletSymbol.filter((item: GouletSymbolFeature) => {
      const { categorie } = item.properties;
      const mix = splitYear(currentYear).toString();
      const prefix = `${currentGouletZone}_${mix}_${currentClimat}_echange_angleterre`;
      return categorie.startsWith(prefix);
    });
  };

  const layer = new IconLayer({
    id: 'echange-angleterre-layer',
    data: getSymbolData(),
    getPosition: (d: any) => {
      return d.position;
    },
    getIcon: (d: any) => d.properties.categorie,
    iconAtlas: IconsMap, // Your image containing all icons
    iconMapping,
    sizeUnits: 'pixels',
    getSize: (d: any) => getSizeIcon(d.properties.categorie, iconMapping),
    pickable: true,
  });

  return [layer];
};

export default useExchangeAngleterreGouletLayer;
