import { IconLayer } from '@deck.gl/layers/typed';

import {
  selectCurrentGouletZone,
  selectCurrentYear,
  selectGouletSymbols,
  selectStateClimate,
} from '../../../store/mainReducer';
import { useAppSelector } from '../../../store/hooks';
import { GouletSymbolFeature } from '../../../models/GouletSymbolFeature';
import { getSizeIcon, splitYear } from '../../../utils';

import IconsMap from '../../../assets/images/goulet/IconsMap.png';

// Define icon mappings for normal and hover states
const iconMapping: any = {
  B_2030_EJCS_diffus: { x: 3, y: 2790, width: 430, height: 418, mask: false, anchorX : 195, anchorY : 170 },
  B_2035_EJCS_diffus: { x: 606, y: 2790, width: 430, height: 418, mask: false, anchorX : 166, anchorY : 168 },
  B_2040_EJCS_diffus: { x: 1278, y: 2790, width: 430, height: 418, mask: false, anchorX : 80, anchorY : 166 },

  B_2035_HNVC_diffus_eolien_1: { x: 3, y: 3320, width: 207, height: 210, mask: false, anchorX : 135, anchorY : 124 },
  B_2035_HNVC_diffus_eolien_3: { x: 760, y: 3349, width: 260, height: 229, mask: false, anchorX : 170, anchorY : 110 },
  B_2040_HNVC_diffus_eolien_1: { x: 360, y: 3320, width: 207, height: 210, mask: false, anchorX : 80, anchorY : 122 },
  B_2040_HNVC_diffus_eolien_2: { x: 590, y: 3320, width: 210, height: 119, mask: false, anchorX : 60, anchorY : 88 },
  B_2040_HNVC_diffus_eolien_3: { x: 860, y: 3349, width: 260, height: 229, mask: false, anchorX : 68, anchorY : 114 },

  C_2030_EJCS_diffus: { x: 3, y: 2790, width: 430, height: 418, mask: false, anchorX : 195, anchorY : 170 },
  C_2035_EJCS_diffus: { x: 606, y: 2790, width: 430, height: 418, mask: false, anchorX : 166, anchorY : 168 },
  C_2040_EJCS_diffus: { x: 1278, y: 2790, width: 430, height: 418, mask: false, anchorX : 80, anchorY : 166 },

  C_2035_HNVC_diffus_eolien_1: { x: 3, y: 3320, width: 207, height: 210, mask: false, anchorX : 135, anchorY : 124 },
  C_2035_HNVC_diffus_eolien_3: { x: 760, y: 3349, width: 260, height: 229, mask: false, anchorX : 170, anchorY : 110 },
  C_2040_HNVC_diffus_eolien_1: { x: 360, y: 3320, width: 207, height: 210, mask: false, anchorX : 80, anchorY : 122 },
  C_2040_HNVC_diffus_eolien_2: { x: 590, y: 3320, width: 210, height: 119, mask: false, anchorX : 60, anchorY : 88 },
  C_2040_HNVC_diffus_eolien_3: { x: 860, y: 3349, width: 260, height: 229, mask: false, anchorX : 68, anchorY : 114 },

  E_2030_EJCS_diffus: { x: 3, y: 2790, width: 430, height: 418, mask: false, anchorX : 195, anchorY : 170 },
  E_2035_EJCS_diffus: { x: 606, y: 2790, width: 430, height: 418, mask: false, anchorX : 166, anchorY : 168 },
  E_2040_EJCS_diffus: { x: 1278, y: 2790, width: 430, height: 418, mask: false, anchorX : 80, anchorY : 166 },

  E_2035_HNVC_diffus_eolien_1: { x: 3, y: 3320, width: 207, height: 210, mask: false, anchorX : 135, anchorY : 124 },
  E_2035_HNVC_diffus_eolien_3: { x: 760, y: 3349, width: 260, height: 229, mask: false, anchorX : 170, anchorY : 110 },
  E_2040_HNVC_diffus_eolien_1: { x: 360, y: 3320, width: 207, height: 210, mask: false, anchorX : 80, anchorY : 122 },
  E_2040_HNVC_diffus_eolien_2: { x: 590, y: 3320, width: 210, height: 119, mask: false, anchorX : 60, anchorY : 88 },
  E_2040_HNVC_diffus_eolien_3: { x: 860, y: 3349, width: 260, height: 229, mask: false, anchorX : 68, anchorY : 114 },

  I_2035_HNVC_diffus_eolien_1: { x: 3, y: 3320, width: 207, height: 210, mask: false, anchorX : 135, anchorY : 124 },
  I_2035_HNVC_diffus_eolien_3: { x: 760, y: 3349, width: 260, height: 229, mask: false, anchorX : 170, anchorY : 110 },
  I_2040_HNVC_diffus_eolien_1: { x: 360, y: 3320, width: 207, height: 210, mask: false, anchorX : 80, anchorY : 122 },
  I_2040_HNVC_diffus_eolien_2: { x: 590, y: 3320, width: 210, height: 119, mask: false, anchorX : 60, anchorY : 88 },
  I_2040_HNVC_diffus_eolien_3: { x: 860, y: 3349, width: 260, height: 229, mask: false, anchorX : 66, anchorY : 114 },

  I_2030_HNVC_diffus_eolien_4: { x: 3, y: 3590, width: 423, height: 444, mask: false, anchorX : 240, anchorY : 228 },
  I_2035_HNVC_diffus_eolien_4: { x: 3, y: 3590, width: 423, height: 444, mask: false, anchorX : 240, anchorY : 228 },
  I_2040_HNVC_diffus_eolien_4: { x: 3, y: 3590, width: 423, height: 444, mask: false, anchorX : 240, anchorY : 228 }, 
};

const useDiffusGouletLayer = () => {
  const currentYear = useAppSelector(selectCurrentYear);
  const currentGouletZone = useAppSelector(selectCurrentGouletZone);
  const currentClimat = useAppSelector(selectStateClimate);
  const gouletSymbol = useAppSelector(selectGouletSymbols);

  const getSymbolData = () => {
    return gouletSymbol.filter((item: GouletSymbolFeature) => {
      const { categorie } = item.properties;
      const mix = splitYear(currentYear).toString();
      const prefix = `${currentGouletZone}_${mix}_${currentClimat}_diffus`;
      return categorie.startsWith(prefix);
    });
  };
  
  const layer = new IconLayer({
    id: 'diffus-layer',
    data: getSymbolData(),
    getPosition: (d: any) => {
      return d.position;
    },
    getIcon: (d: any) =>  d.properties.categorie,
    iconAtlas: IconsMap, // Your image containing all icons
    iconMapping,
    sizeUnits: 'pixels',
    getSize: (d: any) => getSizeIcon(d.properties.categorie, iconMapping),
    pickable: true,
  });

  return [layer];
};

export default useDiffusGouletLayer;
