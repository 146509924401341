/* eslint-disable array-callback-return */
import { DeckProps } from '@deck.gl/core/typed';
import { MapboxOverlay } from '@deck.gl/mapbox/typed';
import Map, { useControl } from 'react-map-gl';

import { sizes } from './breakpoints';
import { CURRENT_YEAR, PROD_COLORS, PROD_LABELS } from './enums';
import { GouletFeature } from '../models/GouletFeature';

export function generateRandomNumber(n: number): number {
  return Math.floor(Math.random() * n) + 1;
}

export const getPieHeight = (wdth: number) => {
  if (wdth <= sizes.md) {
    return 170;
  } else {
    return 234;
  }
};

export const renderSwitchType = (type: string): boolean => {
  switch (type) {
    case 'E':
      return true;
    case 'D':
      return false;
    default:
      return false;
  }
};

const Combinations = ['EJCS', 'EJVS', 'HNCC', 'HNVC'];

export const splitClimate = (status: string) => {
  return status.split('');
};

export const splitYear = (year: string): number => {
  return Number(year.split('_')[1]);
};

export const splitCategory = (categorie: string) => {
  return categorie.split('_')[3];
};

export const getCurrentYear = (str: string) => {
  return CURRENT_YEAR[str as keyof typeof CURRENT_YEAR];
};

export const isValidClimate = (str: string) => Combinations.includes(str);

export const reBuildClimate = (
  selectedClimate: string,
  value: string,
  type: string,
): string => {
  let value1 = splitClimate(selectedClimate)[0];
  let value2 = splitClimate(selectedClimate)[1];
  let value3 = splitClimate(selectedClimate)[2];
  let value4 = splitClimate(selectedClimate)[3];

  switch (type) {
    case 'winter':
      value1 = value1 === value ? value1 : value;
      value2 = value === 'E' ? 'J' : 'N';
      value4 = value === 'E' ? 'S' : 'C';
      break;
    case 'day':
      value2 = value2 === value ? value2 : value;
      break;
    case 'wind':
      value3 = value3 === value ? value3 : value;
      break;
    case 'sun':
      value4 = value4 === value ? value4 : value;
      break;
  }

  const selected = `${value1}${value2}${value3}${value4}` as const;
  return selected;
};

export const checkClimate = (status: string) => {
  return status.split('');
};

export const numberFormatFr = (value: number) => {
  return new Intl.NumberFormat('fr-FR').format(value);
};

export const getCurrentColor = (state: number): any => {
  switch (state) {
    case -1:
      return [70, 70, 70];
    case 0:
      return [225, 89, 137];
    case 1:
      return [70, 223, 17];
    case 2:
      return [255, 186, 0];
    case 3:
      return [255, 88, 0];
    case 4:
      return [255, 15, 0];
  }
};
export const getLegendColor = (str: string) => {
  return PROD_COLORS[str as keyof typeof PROD_COLORS];
};

export const getLegendName = (str: string) => {
  return PROD_LABELS[str as keyof typeof PROD_LABELS];
};

export const getGroupGoulets = (goulets: GouletFeature[], index: string) => {
  const currentGouletFeature = goulets.find((g) => g.properties.id === index);
  return goulets
    .filter((d) => d.properties.zone === currentGouletFeature?.properties.zone)
    .map((elt) => elt.properties.id)
    .join(',');
};

export const getIconePointColor = (state: string): any => {
  switch (state) {
    case 'co2h2':
      return [1, 148, 134, 255];
    case 'industrie':
      return [35, 101, 255, 255];
  }
};

export const getLignePointCategory = (year: string, state: string): any => {
  if (state === 'pas de click' && year === '2024') {
  }
};

export const getLignePointColor = (style: string): any => {
  switch (style) {
    case '225-1':
      return [171, 48, 173, 255];
    case '400-1':
      return [233, 139, 236, 255];
    case '225-2':
      return [171, 48, 173, 255];
    case '400-2':
      return [233, 139, 236, 255];
    case '225-3':
      return [171, 48, 173, 100];
    case '400-3':
      return [233, 139, 236, 120];
    case '400-4':
      return [231, 0, 0, 255];
    case '225-4':
      return [255, 0, 0, 255];
    case '400-6':
      return [83, 222, 209, 255];
    case '225-5':
      return [255, 0, 0, 50];
    case '400-5':
      return [255, 0, 0, 180];
    case '400-7':
      return [0,0,0, 255];
    case '225-6':
      return [83, 222, 209, 255];
  }
};

export const getLignePointSize = (state: string): any => {
  switch (state) {
    case '225-1':
      return 1;
    case '400-1':
      return 5;
    case '225-2':
      return 2;
    case '400-2':
      return 3;
    case '225-3':
      return 2;
    case '400-3':
      return 4;
    case '400-4':
      return 3;
    case '400-5':
      return 3;
    case '225-5':
      return 3;
    case '400-6':
      return 3;
    case '400-7':
      return 3;
  }
};

export const getLigneActivePointSize = (style: string): any => {
  switch (style) {
    case '400-2':
      return 20;
    case '400-4':
      return 20;
    case '400-6':
      return 20;
    case '400-7':
      return 20;
    case '225-2':
      return 15;
    case '225-4':
      return 15;
    case '400-5':
      return 20;
    case '225-6':
      return 15;
  }
};

export const setStylePoint = (style: string): any => {
  switch (style) {
    case 'pas de click':
      return 0.1;
    case '400':
      return 1;
    case '225':
      return 1;
    case 'co2h2':
      return 1;
    case '400 - palier 2':
      return 1;
    case '400-3':
      return 4;
  }
};

export const DeckGLOverlay = (props: DeckProps) => {
  const deck = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  deck.setProps(props);
  return null;
};

export const getSizeIcon = (categorie: string, iconMapping: any): any => {
  const filteredUsers = Object.keys(iconMapping)
  .filter(key => [categorie].includes(key))
  .reduce((obj:any, key) => {
      obj[key] = iconMapping[key];
      return obj;
}, {});

return filteredUsers[categorie] ? filteredUsers[categorie].height : 0;
};
